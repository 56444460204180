(function() {
  jui.dnd = {
    pixelThreshhold: 5,
    ghostIndex: 999999
  };

  var groups = {};
  var activeSource = null;
  var activeGroup = null;
  var activeGhost = null;
  var tracking = false;
  var dragging = false;
  var startPageOffset;
  var startSourceOffset;
  var activeTarget = null;
  var shield = new Element('div', {className:'dnd-shield'});

  function mouseMoveListener(e) {
    var point = {
      left: Event.pointerX(e),
      top: Event.pointerY(e)
    };

    if (activeGhost) {
      positionGhost(point);
    }

    if (dragging) {
      testTargets(point);
    } else {
      if (Math.abs(startPageOffset.left - point.left) >= jui.dnd.pixelThreshhold ||
          Math.abs(startPageOffset.top - point.top) >= jui.dnd.pixelThreshhold) {
        startDragging(point);
      }
    }
  }

  function mouseUpListener(e) {
    tracking = false;
    removeGhost();
    removeShield();
    var point  = {left: Event.pointerX(e), top: Event.pointerY(e)};
    if (dragging) {
      stopDragging(point);
    } else {
      if (activeSource.click) {
        activeSource.click(point);
      }
    }
    $(window.document).stopObserving( 'mouseup', mouseUpListener );
    $(window.document).stopObserving( 'mousemove', mouseMoveListener );
  }

  function initDragging(point) {
    if (activeSource.dragInit) {
      activeSource.dragInit(point);
    }

    if (activeGhost === null) {
      initGhost(point);
    }

    initShield();
  }

  function startDragging(point) {
    dragging = true;
    activeGroup.dragStart(point, activeSource);
    if (activeSource.dragStart) {
      activeSource.dragStart(point);
    }
    if (activeGhost === null) {
      initGhost(point);
    }
    initShield();
  }

  function stopDragging(point) {
    dragging = false;

    removeGhost();
    removeShield();

    if (activeTarget !== null && activeTarget.drop) {
      activeTarget.drop(point, activeSource);
      activeTarget = null;
    }

    if (activeSource.dragStop) {
      activeSource.dragStop(point);
    }
  }

  function initGhost(point) {
    activeGhost = (typeof activeSource.getGhost === "function") ? activeSource.getGhost() : null;
    if (activeGhost) {
      document.body.insert(activeGhost);
      positionGhost(point);
    }
  }

  function initShield() {
    document.body.insert(shield);
  }

  function removeGhost() {
    if (activeGhost) {
      activeGhost.remove();
      activeGhost = null;
    }
  }

  function removeShield() {
    if (shield.parentNode === null) {
      return;
    }
    shield.remove();
  }

  function positionGhost(point) {
    if (activeSource.centerGhost) {
      activeGhost.style.left = (point.left - (Math.round(activeGhost.getWidth() / 2))) + 'px';
      activeGhost.style.top = (point.top - (Math.round(activeGhost.getHeight() / 2))) + 'px';
    } else {
      activeGhost.style.left = (point.left + startSourceOffset.left) + 'px';
      activeGhost.style.top = (point.top + startSourceOffset.top) + 'px';
    }
  }

  function targetFinder(point) {
    return new jui.dnd.TargetFinder(point, activeSource, activeGroup);
  }

  function testTargets(point) {
    /* jshint maxcomplexity:12 */
    var finder = targetFinder(point);

    if(finder.isTargetVisible()) {
      var target = finder.target();

      if (target !== activeTarget) {
        var oldTarget = activeTarget;
        activeTarget = target;
        if (oldTarget !== null) {
          if (oldTarget.dragLeave) {
            oldTarget.dragLeave(point, activeSource);
          }
          if (activeSource.dragLeave) {
            activeSource.dragLeave(point, oldTarget);
          }
        }
        if (activeTarget !== null) {
          if (activeTarget.dragEnter) {
            activeTarget.dragEnter(point, activeSource);
          }
          if (activeSource.dragEnter) {
            activeSource.dragEnter(point, activeTarget);
          }
        }
      } else if (activeTarget !== null) {
        if (activeTarget.dragOver) {
          activeTarget.dragOver(point, activeSource);
        }
        if (activeSource.dragOver) {
          activeSource.dragOver(point, activeTarget);
        }
      }
    }
  }

  jui.dnd.manager = {
    getGroup: function(id) {
      return groups[id];
    },

    addGroup: function(id, g) {
      if (typeof groups[id] !== 'undefined') {
        throw "group with id "+id+" alread exists";
      }

      groups[id] = g;
      return g;
    },

    startTracking: function(source, e, options) {
      options = options || {};
      tracking = true;
      activeSource = source;
      activeGroup = source.getGroup();

      var x = Event.pointerX(e);
      var y = Event.pointerY(e);
      var sourceOffset = source.elm.cumulativeOffset();

      startPageOffset = {left: x, top: y};
      startSourceOffset = {left: sourceOffset.left - x, top: sourceOffset.top - y};

      var point = {
        left: Event.pointerX(e),
        top: Event.pointerY(e)
      };

      if (!options.threshhold) {
        initDragging(point);
      }

      $(window.document).observe( 'mouseup', mouseUpListener );
      $(window.document).observe( 'mousemove', mouseMoveListener );
    },

    retestTargets: function(source, point) {
      if (activeSource !== source) {
        return;
      }

      activeTarget = null;
      testTargets(point);
    },

    leaveCurrentTarget: function(source, point) {
      if (activeSource !== source) {
        return;
      }

      var oldTarget = activeTarget;

      if (oldTarget !== null) {
        if (oldTarget.dragLeave) {
          oldTarget.dragLeave(point, activeSource);
        }
        if (activeSource.dragLeave) {
          activeSource.dragLeave(point, oldTarget);
        }
      }

      activeTarget = null;
    }
  };
})();
